import React, { useEffect, useState } from "react";
import UAParser from "ua-parser-js";

export type StoreClickLocation = "LandingTop" | "LandingBottom" | "FAQ" | "Blog";

type StoreLinkProps = {
    className?: string;
    location: StoreClickLocation;
}

const macStoreURL = "https://apps.apple.com/app/apple-store/id1546738316";

const appendMacUrl = (url: string, cid: string) => url + `?pt=122503656&ct=${cid}&mt=8`;

export const MacStoreLink: React.FC<StoreLinkProps> = ({ location, className, children }) => {

    const [url, setUrl] = useState(macStoreURL);

    useEffect(() => {
        const cid = getCampaignId();
        if(cid) setUrl(appendMacUrl(macStoreURL, cid));
    }, []);

    const onClick = () => {

        const { name } = new UAParser().getOS();
        if(name === "Mac OS") window.gtag("event", "click", { event_category: "outbound", event_label: `MacStoreNative_${location}` });
        else window.gtag("event", "click", { event_category: "outbound", event_label: `MacStore_${location}` });
    }

    return (
        <a className={className} onClick={onClick} href={url} target="_blank">{children}</a> 
    );
};

const windowsWebStoreURL = "https://www.microsoft.com/store/apps/9p2wscjjx1s2";
const windowsNativeStoreURL = "ms-windows-store://pdp/?ProductId=9p2wscjjx1s2";

const appendWinURL = (url: string, cid: string) => url + `?cid=${cid}`;
const appendWinNativeURL = (url: string, cid: string) => url + `&cid=${cid}`;

export const WinStoreLink: React.FC<StoreLinkProps> = ({ location, children }) => {

    const [webUrl, setWebURL] = useState(windowsWebStoreURL);
    const [nativeUrl, setNativeURL] = useState(windowsNativeStoreURL); 

    useEffect(() => {
        const cid = getCampaignId();
        if(cid) {
            setWebURL(appendWinURL(webUrl, cid));
            setNativeURL(appendWinNativeURL(nativeUrl, cid));
        }
    }, []);

    const onClickCapture = (ev: React.MouseEvent<HTMLElement, MouseEvent>) => {

        const { name, version } = new UAParser().getOS();

        if(name === "Windows" && (version === "10" || version === "11")) {
            ev.preventDefault();
            if(window.gtag) window.gtag("event", "click", { event_category: "outbound", event_label: `WinStoreNative_${location}`});
            window.open(nativeUrl, "_self");
        }
        else {
            if(window.gtag) window.gtag("event", "click", { event_category: "outbound", event_label: `WinStore_${location}`});
        }
    };

    return (
        <span onClickCapture={onClickCapture}>
            <a href={webUrl} target="_blank">
                {children}
            </a>
        </span>
    );
}

export const getCampaignId = (): string | undefined => {

    if(window === undefined) return;

    const urlCampaign = new URLSearchParams(window.location.search).get("cid");

    if(urlCampaign !== null) return urlCampaign;

    const storageCampaign = window.localStorage.getItem("cid");

    if(storageCampaign !== null) return storageCampaign;

    return;
}